import React from "react"
import kebabCase from "lodash/kebabCase"
import { Link } from "gatsby"

const TagList = ({ tags }) => {
  return (
    <span>
      <ul className="list-inline">
        {tags.sort().map(( tag, index ) => {
          return (
            <li key={index}>
              <Link to={`/tags/${kebabCase(tag)}/`}>
                  {tag}
              </Link>
            </li>
          )
        })}
      </ul>
    </span>
  )
}

export default TagList
